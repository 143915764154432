






export default {
  name: "Highlights"
}
